import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Observer, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { AppState } from '@/store/state';
import { ActivatedRoute, Router } from '@angular/router';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { NavigationService } from '@services/navigation.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-content',
  templateUrl: './news-content.component.html',
  styleUrls: ['./news-content.component.scss']
})
export class NewsContentComponent implements OnInit {
  private state$: Observable<any>;
  newsId: Number = null;
  newsObj;

  shareTitle = "";
  shareDescription = "";
  salesicon = null;

  modal: NgbModalRef;
  @ViewChild('shareModal') public shareModal: TemplateRef<any>;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      private navigation: NavigationService,
      private router: Router,
      private metaTagService: Meta,
      private titleService: Title
  ) {
    if(this.router.url == 'news' || this.router.url == '/news' || this.router.url == '/news/') {
      this.titleService.setTitle('擁抱智慧通訊，體驗電訊巴打App的五大好處 - 電訊巴打');
      this.metaTagService.updateTag({name: 'og:title', content: '擁抱智慧通訊，體驗電訊巴打App的五大好處 - 電訊巴打'});
    }

    this.metaTagService.updateTag({name: 'og:description', content: '在數位化的浪潮席捲下，電訊巴打App成為了香港人日常生活中的重要一員。這款App顛覆了傳統通訊的界限，為用戶帶來了便捷、高效和個性化的全新體驗。以下是使用電訊巴打App的五大好處，讓你的數位生活更上一層樓：'});
    this.metaTagService.updateTag({name: 'description', content: '在數位化的浪潮席捲下，電訊巴打App成為了香港人日常生活中的重要一員。這款App顛覆了傳統通訊的界限，為用戶帶來了便捷、高效和個性化的全新體驗。以下是使用電訊巴打App的五大好處，讓你的數位生活更上一層樓：'});
  }

  ngOnInit(): void {
    var id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    if(id) {
      this.newsId = parseInt(id);
      this.loadNews();
    } else {
      this.newsId = null;
    }
    this.store.dispatch(new UpdateSelectedNewsId(this.newsId));
    this.store.select('state').subscribe((n) => {
      this.newsId = n.newsId;
      if(n != null && n.newsId != null) {
        this.loadNews();
      }
    })
  }

  async ngAfterViewInit() {
    await new Promise(resolve => { setTimeout(() => { resolve(0); }, 100); });
  }

  loadNews() {
    this.appService.dataGet("news", {
      key: "id",
      value: this.newsId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.newsObj = result.payload.results.pop();
        this.shareTitle = this.newsObj.title;
        this.shareDescription = this.newsObj.title;

        // this.metaTagService.addTags([
        //   {name: 'og:type', content: 'article'},
        //   {name: 'og:title', content: this.newsObj.title + ' - 電訊巴打'},
        //   {name: 'og:description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')},
        //   {name: 'og:image', content: this.newsObj.banner},
        // ]);
        this.metaTagService.updateTag({name: 'og:type', content: 'article'});
        this.metaTagService.updateTag({name: 'og:title', content: this.newsObj.title + ' - 電訊巴打'});
        this.metaTagService.updateTag({name: 'og:description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')});
        this.metaTagService.updateTag({name: 'description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')});
        this.metaTagService.updateTag({name: 'og:image', content: this.newsObj.banner});
        this.titleService.setTitle(this.newsObj.title + ' - 電訊巴打');
      }
    })
  }

  ngOnDestroy(): void {
  }

  onClose(): void {
    this.router.navigateByUrl('news');
    this.store.dispatch(new UpdateSelectedNewsId(null));
    this.store.dispatch(new SetUiMode('listview'));
  }

  onShare(): void {
    this.modal = this.modalService.open(this.shareModal, { size: 'md' });
  }

}
