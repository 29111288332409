import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Observer, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { Store } from '@ngrx/store';
import { StateState } from '@/store/state/state';
import { AppState } from '@/store/state';
import { ActivatedRoute, Router } from '@angular/router';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { NavigationService } from '@services/navigation.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss']
})
export class HomeContentComponent implements OnInit {
  private state$: Observable<any>;
  newsId: Number = null;
  newsObj;

  shareTitle = "";
  shareDescription = "";
  salesicon = null;

  banners = null;
  type = "home";
  selectedPlanType = "";
  tags = [];
  selectedTag = null;

  providers = null;
  allproviders = null;
  plans = {};

  throttle = 0;
  distance = 2;
  page = 1;
  news = [];

  modal: NgbModalRef;
  @ViewChild('shareModal') public shareModal: TemplateRef<any>;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      private navigation: NavigationService,
      private router: Router,
      private metaTagService: Meta,
      private titleService: Title
  ) {
    if(this.router.url == 'news' || this.router.url == '/news' || this.router.url == '/news/') {
      this.titleService.setTitle('最新消息 - 電訊巴打');
    }

    this.metaTagService.updateTag({name: 'og:description', content: '「電訊巴打」APP是專為大家提供至經濟電訊優惠的資訊，如要查找最平電話月費、電訊報價、各個電訊公司優惠等，「電訊巴打」可為你找出至抵的一項，為用戶帶來方便。'});
    this.metaTagService.updateTag({name: 'description', content: '「電訊巴打」APP是專為大家提供至經濟電訊優惠的資訊，如要查找最平電話月費、電訊報價、各個電訊公司優惠等，「電訊巴打」可為你找出至抵的一項，為用戶帶來方便。'});
  }

  ngOnInit(): void {
    this.appService.dataGet('news', null, null, null, null, 'date', 20, (this.page - 1) * 10).then((result) => {
      this.news = result.payload.results;
    });
    var id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    if(id) {
      this.newsId = parseInt(id);
      this.loadNews();
    } else {
      this.newsId = null;
    }
    this.store.dispatch(new UpdateSelectedNewsId(this.newsId));
    this.store.select('state').subscribe((n) => {
      this.newsId = n.newsId;
      this.allproviders = n.providers;
      if(n != null && n.newsId != null) {
        this.loadNews();
      }

      if(n.banners) {
        this.banners = n.banners.filter(b => b.type == this.type);
        // console.log(this.banners);
      }

      if(n.providers) {
        this.providers = n.providers.filter(b => b.category == 'mobile');
        this.onChangePlanType('mobile');
        // this.providers.forEach(async (provider) => {
        //   if(this.plans.hasOwnProperty(provider.id) == false || this.plans[provider.id].length == 0)
        //     this.plans[provider.id] = await this.getPlans(provider.id);
        // });
        // console.log(this.providers);
      }
    });
  }

  async ngAfterViewInit() {
    await new Promise(resolve => { setTimeout(() => { resolve(0); }, 100); });
  }

  loadNews() {
    this.appService.dataGet("news", {
      key: "id",
      value: this.newsId.toString()
    }).then((result) => {
      if(result.payload.results.length > 0) {
        this.newsObj = result.payload.results.pop();
        this.shareTitle = this.newsObj.title;
        this.shareDescription = this.newsObj.title;

        // this.metaTagService.addTags([
        //   {name: 'og:type', content: 'article'},
        //   {name: 'og:title', content: this.newsObj.title + ' - 電訊巴打'},
        //   {name: 'og:description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')},
        //   {name: 'og:image', content: this.newsObj.banner},
        // ]);
        this.metaTagService.updateTag({name: 'og:type', content: 'article'});
        this.metaTagService.updateTag({name: 'og:title', content: this.newsObj.title + ' - 電訊巴打'});
        this.metaTagService.updateTag({name: 'og:description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')});
        this.metaTagService.updateTag({name: 'description', content: (this.newsObj.content.length > 200 ? this.newsObj.content.substring(0, 200) + '...' : this.newsObj.content).replace( /(<([^>]+)>)/ig, '')});
        this.metaTagService.updateTag({name: 'og:image', content: this.newsObj.banner});
        this.titleService.setTitle(this.newsObj.title + ' - 電訊巴打');
      }
    })
  }

  ngOnDestroy(): void {
  }

  onClose(): void {
    this.router.navigateByUrl('news');
    this.store.dispatch(new UpdateSelectedNewsId(null));
    this.store.dispatch(new SetUiMode('listview'));
  }

  onShare(): void {
    this.modal = this.modalService.open(this.shareModal, { size: 'md' });
  }

  bannerClick(banner) {
    if(banner.link) {
      window.open(banner.link);
    }
  }

  async getPlans(selectedProviderId: number) {
    var search = [{
      key: 'type',
      value: [this.selectedPlanType]
    }];
    if(selectedProviderId) {
      search.push({key: 'TeleBro_providers_id', value: [selectedProviderId.toString()]});
    }
    if(this.selectedTag) {
      search.push({key: 'tags', value: [this.selectedTag.name]});
    }
    let result = await this.appService.dataGet('plans', {
      key: 'home',
      value: '1'
    }, search, null, null, 'rand()', 10, 0);
    return result.payload.results
  }

  onChangePlanType(type) {
    if(this.selectedPlanType != type) {
      this.selectedPlanType = type;
      this.selectedTag = null;
      this.providers = this.allproviders.filter(b => b.category == this.selectedPlanType);
      this.providers.forEach(async (provider) => {
        if(this.plans.hasOwnProperty(provider.id) == false || this.plans[provider.id].length == 0)
          this.plans[provider.id] = await this.getPlans(provider.id);
      });
      this.appService.dataGet('tags', null, [{
        key: 'type',
        value: [type]
      }]).then((result) => {
        this.tags = result.payload.results;
      });
    }
  }

  onChangeTag(tag) {
    if(this.selectedTag != tag) {
      this.selectedTag = tag;
      this.providers.forEach(async (provider) => {
        this.plans[provider.id] = await this.getPlans(provider.id);
      });
    }
  }

  async onScroll(): Promise<void> {
    this.page++;
    this.appService.dataGet('news', null, null, null, null, 'date', 10, (this.page - 1) * 10).then((result) => {
      this.news.push(...result.payload.results);
    });
  }

}
