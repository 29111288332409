<div style="display: flex" [class]="pageType != 'news' && uiMode == 'contentview' && planId ? 'plan-detail-page' : null">
  <div class="side-bar">
    <div class="heading">
      <a href="https://www.telecombrother.com/"><img src="/assets/img/icon-white.png" /></a></div>

    <div class="pt-2 pb-2"><a [href]="'/news'" (click)="onViewChangeToNews($event)">
      <div><img [src]="pageType == 'news' ? 'assets/img/botbtn-selected-news.png' : 'assets/img/botbtn-unselect-news.png'" /></div>
      <div [class]="pageType == 'news' ? 'selected' : ''">最新消息</div>
    </a></div>
    <div class="pt-2 pb-2"><a [href]="'/plans/fixedwired'" (click)="onViewChange($event, 'fixedwired')">
      <div><img [src]="pageType == 'fixedwired' ? 'assets/img/botbtn-selected-homelan.png' : 'assets/img/botbtn-unselect-homelan.png'" /></div>
      <div [class]="pageType == 'fixedwired' ? 'selected' : ''">固網寛頻</div>
    </a></div>
    <div class="pt-2 pb-2"><a [href]="'/plans/fixedcellular'" (click)="onViewChange($event, 'fixedcellular')">
      <div><img [src]="pageType == 'fixedcellular' ? 'assets/img/botbtn-selected-home.png' : 'assets/img/botbtn-unselect-home.png'" /></div>
      <div [class]="pageType == 'fixedcellular' ? 'selected' : ''">家居5G寬頻</div>
    </a></div>
    <div class="pt-2 pb-2"><a [href]="'/plans/mobile'" (click)="onViewChange($event, 'mobile')">
      <div><img [src]="pageType == 'mobile' ? 'assets/img/botbtn-selected-mobile.png' : 'assets/img/botbtn-unselect-mobile.png'" /></div>
      <div [class]="pageType == 'mobile' ? 'selected' : ''">手機數據</div>
    </a></div>
    <div class="pt-2 pb-2"><a [href]="'/plans/tv'" (click)="onViewChange($event, 'tv')">
      <div><img [src]="pageType == 'tv' ? 'assets/img/botbtn-selected-tv.png' : 'assets/img/botbtn-unselect-tv.png'" /></div>
      <div [class]="pageType == 'tv' ? 'selected' : ''">收費電視</div>
    </a></div>
  </div>
  <div style="flex-grow: 1">
    <div class="main-navbar">
      <div class="heading" style="display: none;">
        <a href="/"><img src="/assets/img/icon-white.png" /></a>
      </div>
      <div class="social-btn">
        <a href="https://www.facebook.com/telecomebrotherhk" target="_blank"><img src="/assets/img/facebook.png" /></a>
        <a href="https://instagram.com/telecombrotherhk" target="_blank"><img src="/assets/img/instagram.png" /></a>
        <a href="https://wa.me/85294455596" target="_blank"><img src="/assets/img/whatsap.png" /></a>
      </div>
      <div class="right text-white" style="height: 70px;">
        <div style="display: inline-block">
          <div style="transform: translateY(-50%); margin-top: 35px;">
            <div class="mr-2" style="font-size: 125%; cursor: pointer;" (click)="onMemberClick()"><i class="fas fa-user mr-1"></i><span class="viewcount" *ngIf="!appService.user">會員登入</span><span class="viewcount" *ngIf="appService.user">你的帳戶</span></div>
            <div class="mr-2 viewcount" style="font-size: 80%" *ngIf="viewcount">瀏覽次數：{{ viewcount }}</div>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <!-- <div class="calc-btn"><a routerLink="/calculator"><img src="/assets/img/btn-calculator.png" /></a></div> -->
  <!-- <div class="companyname">© Kin & Fung Creative Tech Ltd</div> -->
</div>
