import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, Injector, OnInit, PLATFORM_ID, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '@components/button/button.component';
import { Observable, Subject } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ColumnConfig, TableComponent } from '@components/table/table.component';

import { StateState } from '@/store/state/state';
import { Store } from '@ngrx/store';
import { SetUiMode, UpdateSelectedNewsId } from '@/store/state/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { AppState } from '@/store/state';
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  viewMode: string;

  constructor(
      private toastr: ToastrService,
      private appService: AppService,
      private modalService: NgbModal,
      public viewContainerRef: ViewContainerRef,
      private injector: Injector,
      private store: Store<AppState>,
      private route: ActivatedRoute,
      public router: Router,
      private navigation: NavigationService,
      private metaTagService: Meta,
      @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  ngOnInit(): void {
    if (this.router.url == '' || this.router.url == '/') {
      this.store.dispatch(new SetUiMode('webroot'));
    } else if (this.router.url == '/news/') {
      this.store.dispatch(new SetUiMode('listview'));
    } else {
      this.store.dispatch(new SetUiMode('contentview'));
    }

    this.store.select('state').subscribe((s) => {
      this.viewMode = s.uiMode;
    })
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {

  }

  get isBrowserOnly(): boolean {
     return isPlatformBrowser(this.platformId);
  }
}
